// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
window.$ = window.jQuery = require('jquery');
require("semantic-ui-sass/semantic-ui")
require("whatwg-fetch")
require('jquery-datetimepicker')
require('!style-loader!css-loader!jquery-datetimepicker/jquery.datetimepicker.css')

window.Promise = require("promise")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.check_subdomain = function(value) {
  // if (value.match(/^[A-Za-z0-9][A-Za-z0-9\-]{1,61}[A-Za-z0-9]/)) {
  if (value.match(/^[A-Za-z0-9][A-Za-z0-9\-]+[A-Za-z0-9]+$/)) {
    //サブドメインの重複確認
    var clients_subdomains = JSON.parse($('.clients_subdomains').text());
    if (clients_subdomains.indexOf(value) >= 0) {
      $('.check_subdomain_message').text('同じサブドメインがすでに登録されています').addClass('error').removeClass('ok');
      $('.subdomain_regular_expression').addClass('error').removeClass('ok');
      $("input[type='submit']").attr('disabled', true)
    } else {
      $('.check_subdomain_message').text('サブドメインで使える形式です').addClass('ok').removeClass('error');
      $('.subdomain_regular_expression').addClass('ok').removeClass('error');
      $("input[type='submit']").attr('disabled', false)
    }
  } else {
    $('.check_subdomain_message').addClass('error').removeClass('ok');
    if (value == "") {
      $('.check_subdomain_message').text('サブドメインを入力してください');
    } else {
      $('.check_subdomain_message').text('サブドメインでは使えない形式です');
    }
    $('.subdomain_regular_expression').addClass('error').removeClass('ok');
    $("input[type='submit']").attr('disabled', true)
  }
};

window.make_password = function(len=8) {
  var letters = 'abcdefghijklmnopqrstuvwxyz';
  var string  = letters + letters.toUpperCase() + '0123456789';
  var password='';

  for (var i = 0; i < len; i++) {
    password += string.charAt(Math.floor(Math.random() * string.length));
  }
  return password;
};

window.setNotificationTimeout = function () {
  setTimeout(
    function() {
      var notification = $(".notifications").fadeOut();
      notification.empty();
    },
    2000
  );
};

window.showNotification = function(type, msg) {
  let new_elm = document.createElement('div');
  if (type != "error") {
    new_elm.setAttribute('class', `ui ${type} message transition`);
    new_elm.textContent = msg;
    let parent = document.getElementsByClassName('notifications')[0];
    parent.appendChild(new_elm);
    $(parent).show();
    setNotificationTimeout();
  } else {
    let close_icon = document.createElement('i');
    close_icon.setAttribute('class', 'close icon');
    close_icon.addEventListener('click', function() {
      $(this)
        .closest('.message')
        .transition('fade');
    });
    new_elm.setAttribute('class', `ui ${type} message transition`);
    new_elm.appendChild(close_icon);
    new_elm.appendChild(document.createTextNode(msg));
    let parent = document.getElementsByClassName('errors')[0];
    parent.appendChild(new_elm);
    $(parent).show();
  }
};

function addIndicatorForRequired() {
  let required_inputs = document.querySelectorAll(".field > input:required");
  Array.prototype.forEach.call(required_inputs, function(elm) {
    elm.parentElement.classList.add('required');
  });
}

function with_order_icon(str, order)
{
  return str + "　▲▼"[order];
}

function next_order(order) {
  return order < 2 ? order + 1 : 0;
}

$(function () {
  $.datetimepicker.setLocale('ja');
  $('.datetimepicker').datetimepicker();

  // flashメッセージの管理
  if ($(".notifications").text() != "") {
    setNotificationTimeout();
  }
  $('.message .close').on('click', function() {
    $(this)
      .closest('.message')
      .transition('fade');
  });

  // 動画アップロード
  $('.upload_file_input').on('change', function () {
    $(this).next('.upload_file_label').text($(this).prop('files')[0].name);
  });

  // 資料アップロード
  $('.documents_file_input').on('change', function () {
    $('.documents_file_label').text($(this).get(0).files.length + 'ファイル');
  });

  // semantic ui関連の機能有効化
  $('.ui.dropdown').dropdown();
  $('.popup_simple').popup();
  $('.popup_next').popup({inline: true});
  $('.ui.accordion').accordion();
  addIndicatorForRequired();

  // サブドメインの正規表現
  $(document).on('keyup', '.subdomain_regular_expression', function (e) {
    this.value = this.value.replace(/[^a-z0-9\-]+/i, '');
    check_subdomain(this.value);
  });
  $(document).on('blur', '.subdomain_regular_expression', function () {
    this.value = this.value.replace(/[^a-z0-9\-]+/i, '');
    check_subdomain(this.value);
  });

  //サブドメインが重複している状態でinputからフォーカスが外れたときにvalueを削除
  if ($('div').hasClass('clients_subdomains')) {
    var clients_subdomains = JSON.parse($('.clients_subdomains').text());
    $('.subdomain_regular_expression').change(function () {
      if (clients_subdomains.indexOf($(this).val()) >= 0) {
        $(this).val('').addClass('error').removeClass('ok');
        $("input[type='submit']").attr('disabled', true)
      }
    });
  }

  // タブレット幅以下のメニュー
  var window_height = $(window).height();
  $('.sp_nav').innerHeight(window_height - 60);
  $(document).on('click', '.menu-trigger', function () {
    if ($('.menu-trigger').hasClass('active')) {
      $('.sp_nav').fadeOut();
      $('.menu-trigger').removeClass('active');
    } else {
      $('.sp_nav').fadeIn();
      $('.menu-trigger').addClass('active');
    }
  });

  let sorters = $('.sorter');
  if (sorters.length > 0) {
    let sort_target_input = document.forms[0].elements.sort_target;
    let sort_order_input = document.forms[0].elements.sort_order;
    sorters.each(function() {
      let target_param = this.dataset.orderParam;
      let target = sort_target.value;
      let order = parseInt(sort_order.value) || 0;
      if (target_param == target) {
        this.textContent = with_order_icon(this.textContent, order);
      } else {
        this.textContent = with_order_icon(this.textContent, 0);
      }
    });
    sorters.on('click', function() {
      let target_param = this.dataset.orderParam;
      let target = sort_target.value;
      let order_input = document.forms[0].elements.sort_order;
      let order = parseInt(order_input.value) || 0;
      if (target_param == target) {
        order_input.value = next_order(order);
      } else {
        order_input.value = 1;
      }
      sort_target.value = target_param;
      document.forms[0].submit();
    });
  }

});
